/* The Modal (background) */
.ng-modal {
    display:none;
    position:fixed;
    z-index:1000;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow:auto;
    background:hsla(0,0,0,0.54);
    animation:modalFadeIn 0.5s;
    .content {
        background-color:#fff;
        margin:0;
        padding:$gutter*2 $gutter $gutter;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        box-shadow:rgba(0, 0, 0, 0.247059) 0px 14px 45px, rgba(0, 0, 0, 0.219608) 0px 10px 18px;
        border-radius:$borderRadius;
        width:50%;
        min-width:300px;
        max-width:600px;
        animation:modalSlideIn 0.5s;
        max-height:98%;
        overflow-y:scroll;
    }

    .close {
        color:hsl(187, 100, 42);
        position:absolute;
        right:10px;
        top:10px;
        font-size:28px;
        display:block;
        width:24px;
        height:24px;
        line-height:24px;
        text-align:center;
        transition:color 0.2s;
        z-index:2;
        &:hover,
        &:focus {
            color:$textColor;
            text-decoration:none;
            cursor:pointer;
        }
    }
}
@keyframes modalFadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes modalSlideIn {
    0% {
        margin-top:-100px;
    }
    100% {
        margin-top:0;
    }
}
