.pagination-centered {
    text-align:center;
}
.tags-pagination {
    list-style-type:none;
    display:inline-block;
    margin:1em auto;
    padding:0;
    @extend %clearfix;
    li {
        float:left;
        margin:0 1px 0 0;
        &.to-first,
        &.to-last,
        .page-of {
            display:none;
        }
        &.active {
            .tags-btn {
                cursor:default;
            }
        }
    }
    .tags-btn {
        border-radius:0;
        .md-icon {
            margin:0;
        }
    }
    @media screen and (max-width:600px) {
        li {
            &.to-page:not(.active) {
                display:none;
            }
            &.to-first,
            &.to-last {
                display:block;
            }
            .page-of {
                display:inline-block;
            }
        }
    }
}
