.tags-resizable {
    position:relative;
    .tags-resizable-handle {
        position:absolute;
        right:-2px;
        top:0;
        bottom:0;
        z-index:100;
        width:4px;
        background:transparent;
        cursor:ew-resize;
    }
}
