#{$namespace} {
    input[type='checkbox'],
    input[type='radio'] {
        position:absolute;
        pointer-events:all;
        opacity:0;
        left:-9999em;
        + label {
            position:relative;
            padding-left:2.5em;
            cursor:pointer;
            display:block;
            margin:1em 0;
            min-height:22px;
            &::before,
            &::after {
                @extend %material-icons;
                position:absolute;
                left:0;
                top:-2px;
                transition:all 0.2s ease-out;
            }
            &::after {
                opacity:0;
                transform:scale(0);
                color:$linkColor;
            }
        }
        &:checked {
            + label {
                &::before {
                    opacity:0;
                }
                &::after {
                    opacity:1;
                    transform:scale(1);
                }
            }
        }
        &:disabled {
            + label {
                opacity:0.2;
                cursor:default;
            }
        }
    }
    input[type='checkbox'] {
        + label {
            &::before {
                content:"check_box_outline_blank";
            }
            &::after {
                content:"check_box";
            }
        }
        &:checked {
            + label {
                &::before {
                    color:$linkColor;
                }
            }
        }
    }
    input[type='radio'] {
        + label {
            &::before {
                content:"radio_button_unchecked";
                transition:all 0.3s ease-out;
            }
            &::after {
                content:"radio_button_checked";
                transition:all 0.1s ease-out;
            }
        }
        &:checked {
            + label {
                &::before {
                    opacity:0;
                    transform:scale(0);
                    transition:transform 0.2s ease-out, opacity 0.5s;
                }
                &::after {
                    transition:all 0.3s ease-out;
                }
            }
        }
    }
}

.ng-tags-input {
    margin:0 0 1.5em;
    label {
        display:block;
        margin:0 0 0.25em;
    }

    input[type=text] {
        display:block;
        width:100%;
        max-width:500px;
        border:1px solid $borderColor;
        height:$btnHeight;
        line-height:$btnHeight;
        padding:0 0.75em;
        border-radius:$borderRadius;
        font-size:1em;
    }
    &.error-input {
        input[type=text] {
            border-color:$errorColor;
        }
        ul {
            list-style-type:none;
            margin:0;
            padding:0;
            li {
                color:$errorColor;
                padding:0.25em 0;
                font-weight:700;
                font-size:12px;
            }
        }
    }
    .tag-keyword {
        display:inline-block;
        height:$btnHeight;
        line-height:$btnHeight;
        float:left;
        padding:0 1em;
        white-space:nowrap;
        overflow:ellipsis;
        background:hsla(0, 0, 100, 0.35);
        border-radius:$borderRadius;
        border-top-right-radius:0;
        border-bottom-right-radius:0;
        + .tags-btn {
            border-top-left-radius:0;
            border-bottom-left-radius:0;
        }
    }
}

.ng-tags-content-type-filter {
    form {
        background: #e6e5e5;
        padding: .8em;
        margin: .5em 0;
        font-size: 13px;

        select[multiple] {
            display:block;
            width: 100%;
            max-width:500px;
            border: 0;
            margin: 0 0 1em;

            option {
                padding: .25em;
            }
        }

        select:not([multiple]) {
            display:block;
            width:100%;
            max-width:500px;
            border:1px solid $borderColor;
            height:$btnHeight;
            line-height:$btnHeight;
            padding:0 0.75em;
            border-radius:$borderRadius;
            font-size:1em;
            background:#ffffff;
            margin: 0 0 1em;
        }
    }
}
