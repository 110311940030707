#{$namespace} {
    a {
        color:$linkColor;
        text-decoration:none;
        &:hover {
            text-decoration:underline;
        }
    }
    h1, h2, h3, h4 {
        font-weight:500;
        margin:2em 0 1em;
    }
    h1 {
        font-size:2.291em;
    }
    h2 {
        font-size:1.618em;
    }
    h3 {
        font-size:1.416em;
    }
    h4 {
        font-size:1em;
    }
    .tags-badge {
        display:inline-block;
        margin-left:4px;
        vertical-align:middle;
        padding:0 2px;
        font-size:12px;
        font-weight:400;
        line-height:20px;
        height:20px;
        min-width:20px;
        text-align:center;
        background:hsl(0, 0, 50);
        color:#fff;
        border-radius:500px;
    }
}
