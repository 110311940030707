#{$namespace} {
    .tags-tabs {
        margin:2em 0 3.5em;
    }
    .tags-tab-controls {
        list-style-type:none;
        width:100%;
        padding:0;
        margin:0;
        display:flex;
        > li {
            flex:1;
            display:flex;
            align-items:stretch;
            justify-content:center;
            background:hsl(0, 0, 32);
            margin:0;
            &.active {
                .tags-tab-control {
                    &::after {
                        width:100%;
                        transition:width 0.25s ease-out;
                    }
                }
            }
        }
        .tags-tab-control {
            display:block;
            padding:10px 10px;
            text-align:center;
            font-weight:500;
            color:#fff;
            width:100%;
            position:relative;
            transition:background 0.2s;
            &:hover {
                text-decoration:none;
                background:hsla(0, 0, 100, 0.1);
            }
            &::after {
                content:"";
                display:block;
                transition:width 0.1s ease-in;
                position:absolute;
                bottom:0;
                left:50%;
                transform:translateX(-50%);
                height:3px;
                width:0;
                background:$brandDark;
            }
        }
    }
    .tags-tab {
        display:none;
        &.active {
            display:block;
            animation:tabFadeIn 0.35s ease-out;
        }
    }
}
@keyframes tabFadeIn {
    0%{
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
