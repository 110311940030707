#{$namespace} {
    .tag-title {
        margin:0 0 1.5em;
        .tag-title-note {
            font-size:0.5297545471em;
            font-weight:400;
            color:#999;
            display:block;
            strong {
                font-weight:400;
                color:$textColor;
            }
        }
    }

    // tags table
    table {
        width:100%;
        border-spacing:0;
        border-collapse:collapse;
        margin:0 0 1em;
        th,
        td {
            padding:8px 14px;
            border-bottom:1px solid $tableBorder;
            text-align:left;
        }
        th {
            background:$tableBorder;
        }
        td {
            background:#fff;
        }
        input[type='checkbox'],
        input[type='radio'] {
            + label {
                margin:0;
            }
        }
    }
}
