/* ng-tags theme for jstree */
$nodeHeight: 24px;

.jstree-ng-tags {
    font-size:13px;
    margin:0 0 1.5em;
    .jstree-icon {
        @extend %material-icons;
        position:relative;
        z-index:1;
        &:empty {
            width:$nodeHeight;
            height:$nodeHeight;
            line-height:$nodeHeight;
        }
    }
    .jstree-node {
        line-height:$nodeHeight;
        position:relative;
        .jstree-node {
            margin-left:$nodeHeight;
        }
        &::before {
            position:absolute;
            left:$nodeHeight/2;
            top:0;
            content:"";
            height:100%;
            width:0;
            border-left:1px solid #d5d5d5;
            z-index:0;
        }
        &::after {
            position:absolute;
            left:$nodeHeight/2;
            top:$nodeHeight/2;
            content:"";
            height:0;
            width:$nodeHeight/2;
            border-top:1px solid #d5d5d5;
            z-index:0;
        }
        &.jstree-last {
            &::before {
                height:$nodeHeight/2;
            }
        }
        .jstree-anchor {
            white-space:normal;
            word-break:break-word;
            padding-left:26px;
            position:relative;
            .jstree-icon {
                position:absolute;
                left:0;
            }
        }
    }
    .jstree-themeicon {
        font-size:17px;
        color:hsl(0, 0, 74);
        &::before {
            content:"folder";
        }
    }
    .jstree-ocl {
        font-size:17px;
    }
    .jstree-closed,
    .jstree-open,
    .jstree-loading {
        > .jstree-ocl {
            background:$sidebarBg;
            .ng-modal & {
                background-color:#fff;
            }
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            &::before {
                content:"add_circle";
            }
        }
    }
    .jstree-open {
        > .jstree-ocl {
            &::before {
                content:"remove_circle_outline";
            }
        }
    }
    .jstree-loading {
        > .jstree-ocl {
            background:$sidebarBg url(../images/loader.svg) no-repeat center center;
            background-size:20px;
            &::before {
                content:"";
            }
            .ng-modal & {
                background-color:#fff;
            }
        }
    }
}

.jstree-ng-tags-contextmenu.vakata-context {
    border:none;
    background:#fff;
    box-shadow:rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    border-radius:$borderRadius;
    padding:8px 0;
    z-index:100;
    font-family:$baseFont;
    font-size:$baseFontSize;
    color:$textColor;
    text-shadow:none;
    list-style-type:none;
    transition:transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform-origin:left top 0px;
    animation:contextIn 0.18s ease-out;

    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    a {
        color:inherit;
        text-shadow:none;
        border-radius:0;
        padding:0 $gutter;
        line-height:$btnHeight;
        transition:background 0.2s;
        .vakata-contextmenu-sep {
            display:none;
        }
        i {
            display:none;
        }
    }
    a:hover,
    .vakata-context-hover a {
        box-shadow:none;
        background:hsla(0, 0, 0, 0.1);
    }
    .vakata-context-separator {
        border-top:1px solid $borderColor;
        a {
            display:none;
        }
    }
}

@keyframes contextIn {
    0% {
        transform:scale(1, 0);
        opacity:0;
    }
    100% {
        transform:scale(1, 1);
        opacity:1;
    }
}

.tags-tree li.disabled {
    color: gray;
}

.tags-tree a.selected {
    color: $linkColor;
}
