#{$namespace} {
    .tags-btn {
        display:inline-block;
        font-weight:normal;
        text-align:center;
        vertical-align:middle;
        touch-action:manipulation;
        cursor:pointer;
        border:none;
        white-space:nowrap;
        overflow:hidden;
        padding:6px 12px;
        font-size:14px;
        line-height:1.7142857143;
        user-select:none;
        border-radius:$borderRadius;
        background:$btnDefault;
        color:$btnDefaultColor;
        text-decoration:none;
        transition:all 0.2s;
        position:relative;
        &[disabled],
        &.disabled {
            opacity:0.5;
            cursor:not-allowed;
            .tags-btn-effect {
                display:none;
            }
            &:hover,
            &:focus {
                background:$btnDefault;
            }
        }
        &:focus {
            outline:0;
        }
        &:hover,
        &:focus {
            background:darken($btnDefault, 10%);
            text-decoration:none;
        }
        &.tags-btn-primary {
            background:$btnPrimary;
            color:$btnPrimaryColor;
            &[disabled]
            &.disabled {
                &:hover,
                &:focus {
                    background:$btnPrimary;
                }
            }
            &:hover,
            &:focus {
                background:desaturate(darken($btnPrimary, 8%), 10%);
            }
            .tags-btn-effect {
                background:hsla(0, 0, 100, 0.25);
            }
        }
        &.tags-btn-link {
            color:$linkColor;
            background:transparent;
        }
        .md-icon {
            display:inline-block;
            vertical-align:middle;
            margin:0 0.25em 0 0;
            float:left;
            &.right {
                float:right;
                margin:0 0 0 0.25em;
            }
        }
        .tags-btn-effect {
            position:absolute;
            display:block;
            left:50%;
            top:50%;
            transform:translate(-50%, -50%);
            opacity:0;
            background:hsla(0, 0, 0, 0.1);
            border-radius:50%;
            pointer-events:none;
            animation:effectSize 0.5s cubic-bezier(0.39, 0.76, 0.6, 0.98), effectFade 0.5s cubic-bezier(0.34, 0.29, 0.87, 0.57);
        }
    }
    .tags-btn-group {
        @extend %clearfix;
        margin:1em 0;
        > .tags-btn {
            float:left;
            margin-left:1px;
            border-radius:0;
            &:first-child {
                border-top-left-radius:$borderRadius;
                border-bottom-left-radius:$borderRadius;
                margin-left:0;
                border-left:none;
            }
            &:last-child {
                border-top-right-radius:$borderRadius;
                border-bottom-right-radius:$borderRadius;
            }
        }
    }
    .tags-actions {
        margin:1em 0;
        .tags-btn {
            margin-right:0.5em;
        }
    }
}
@keyframes effectSize {
    0% {
        width:0;
        height:0;
    }
}
@keyframes effectFade {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}
